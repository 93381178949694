<template>
    <div v-if="dialog.state.active">
        <div class="dialog-inner min-w-[400px] rounded-lg shadow-lg transition">
            <div class="px-4 pt-4">
                <p class="text-2xl mb-3" v-if="dialog.state.title">{{ dialog.state.title }}</p>
                <p v-if="dialog.state.html" v-html="dialog.state.message" />
                <p class="text-sm" v-else>{{ dialog.state.message }}</p>
                <input v-if="dialog.state.type === 'prompt'" :type="dialog.state.inputType" v-model="userInput" />
            </div>
            <div class="w-full flex justify-end mt-6">
                <el-button ref="submitButton" type="success" @click="dialog.ok(userInput)">{{ dialog.state.okText }}</el-button>
                <el-button type="warning" v-if="dialog.state.type !== 'alert'" @click="dialog.cancel()">{{ dialog.state.cancelText }}</el-button>
            </div>
        </div>
        <div class="dialog-bg" @click="dialog.cancel()" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInput: "",
            dialog: this.$dialog,
        };
    },

    watch: {
        dialog: {
            async handler(newValue) {
                if (newValue.state.active) {
                    setTimeout(() => {
                        this.$refs.submitButton.$el.focus();
                    }, 100);
                }
            },
            deep: true,
        },
    },
};
</script>

<style>
.dialog-bg {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
.dialog-inner {
    z-index: 9999;
    background: white;
    padding: 20px;
    position: fixed;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
}
</style>
